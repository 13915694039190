<template>
  <div>
    <van-nav-bar
      left-text="返回"
      title="同步费用"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
    />
    <van-pull-refresh
      class="page with-nav-bar"
      v-model="state.loading"
      @refresh="loadList(state.station_id)"
    >
      <van-notice-bar
        color="#1989fa"
        background="#ecf9ff"
        left-icon="volume-o"
        scrollable
      >
        温馨提示：费用设置后系统会自动同步到所有在线的设备，如果长时间未同步才手动操作！
      </van-notice-bar>
      <div v-if="state.items.length > 0">
        <van-cell
          v-for="(item, index) in state.items"
          :key="index"
          icon="hotel-o"
        >
          <template #title>
            {{ item.name }}
            <span class="text-danger" v-if="item.offline">(离线)</span>
            <span class="text-success" v-else>(在线)</span>
          </template>
          <template #extra>
            <van-button
              v-if="item.fee_synced_at > 0"
              type="success"
              size="mini"
              plain
              :disabled="
                item.offline ||
                Math.floor(new Date().getTime() / 1000) -
                  Math.abs(item.fee_synced_at) <
                  60
                  ? true
                  : false
              "
              @click="manualSync(item.id)"
              >已同步</van-button
            >
            <van-button
              v-else-if="
                item.fee_synced_at < 0 &&
                Math.floor(new Date().getTime() / 1000) -
                  Math.abs(item.fee_synced_at) <
                  60
              "
              type="warning"
              size="mini"
              plain
              disabled
              >待回复</van-button
            >
            <van-button
              v-else
              type="primary"
              size="mini"
              plain
              :disabled="item.offline ? true : false"
              @click="manualSync(item.id)"
              >未同步</van-button
            >
          </template>
        </van-cell>
      </div>
      <van-loading v-else-if="state.loading" class="mt-3 text-center" vertical
        >加载中</van-loading
      >
      <van-empty v-else image="network" description="暂无充电桩" />
    </van-pull-refresh>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Field, Toast, Checkbox, PullRefresh, NoticeBar } from "vant";
import { syncFeeSettings } from "@/api/device.service";
import { getDevicesFeeStatus } from "@/api/station.service";
export default {
  components: {
    [Field.name]: Field,
    [Checkbox.name]: Checkbox,
    [NoticeBar.name]: NoticeBar,
    [PullRefresh.name]: PullRefresh,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      station_id: 0,
      items: [], // id, name, fee_synced_at
      loading: true,
    });

    const loadList = async (station_id) => {
      try {
        const { result } = await getDevicesFeeStatus(station_id);
        if (result.items.length > 0) {
          state.items = [];
          state.items.push(...result.items);
        }
        state.loading = false;
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    // 注意: 同步逻辑
    // 0. 设备离线不可同步
    // 1. 未同步或上次同步超过60秒充电桩还无相应则可以手动同步
    // 2. 已同步但上次同步超过60秒, 仍然可以手动同步, 主要用于新添加设备如果未能正确同步的时候手动操作
    const manualSync = async (id) => {
      try {
        await syncFeeSettings(id);
        loadList(state.station_id);
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    onMounted(() => {
      const { station_id } = route.query;
      if (!station_id) {
        Toast.fail("参数错误");
        router.back();
      } else {
        state.station_id = station_id;
        loadList(station_id);
      }
    });

    return { state, loadList, manualSync };
  },
};
</script>
